import $ from 'jquery';
import _ from 'lodash';

(function ( $ ) {

  // Requirements:
  // jQuery
  // Lowdash

  $.fn.inView = function() {
    if ($(window).scrollTop() + $(window).height() >= $(this).offset().top && $(window).scrollTop() <= $(this).offset().top + $(this).height()) {
      return true;
    }
    return false;
  };

  $.fn.yPercent = function() {
    return ($(this).offset().top - $(window).scrollTop()) / $(window).height() * 100;
  };

  $.fn.parallax = function( options ) {

    var $this = $(this);
    var $target = $this.find('.js-parallax-watch');
    var settings = $.extend({
      scale: 0.2,
      duration: 0.2,
      buffer: 100
    }, options );
    var transition = `transform ${settings.duration}s ease-out`;
    var transform

    $target.each(function(){
      var $thisTarget = $(this);
      
      $thisTarget.css({
        '-webkit-transition'    :   transition,
        '-moz-transition'       :   transition,
        '-o-transition'         :   transition,
        '-ms-transition'        :   transition,
        'transition'            :   transition,
      });
    })


    $(window).on('scroll', _.throttle(function(){

      if ($this.inView()) {
        $target.each(function(){
          // var $thisTarget = $(this);

          $(this).css({
            '-webkit-transform'   : `translateY(${$this.yPercent() * -settings.scale}%)`,
            '-moz-transform'      : `translateY(${$this.yPercent() * -settings.scale}%)`,
            '-o-transform'        : `translateY(${$this.yPercent() * -settings.scale}%)`,
            '-ms-transform'       : `translateY(${$this.yPercent() * -settings.scale}%)`,
            'transform'           : `translateY(${$this.yPercent() * -settings.scale}%)`
          })
        });
      }


    }, settings.buffer))

  };

  function matchHeight($target) {
    // Initialise at 0
    var currentHeight = 0;

    // All targets to show real height
    $target.css('height', 'auto').addClass('clearfix');

    // Test each target height against initial height
    for (var i = 0; i < $target.length; i++) {
      if ($target.eq(i).height() >= currentHeight) {
        currentHeight = $target.eq(i).height();
      }
    }

    // Set all targets to have equal height
    $target.height(currentHeight);
  }

  $.fn.equalize = function( options ) {

    var $this = $(this);
    var $target;

    // Control over how often this even is fired
    var settings = $.extend({
      buffer: 500
    }, options );

    // when == 1 event will fire on scroll;
    var widthChanged = 1;

    // Find target
    if ($this.data('equalize') != undefined) {
      $target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
    }else {
      $target = $this.find(`[data-equalize-watch]`);
    }

    // On scroll event
    $(window).on('scroll', _.throttle(function(){

      // If this container is visible and the content has changed width
      if (widthChanged && $this.inView()) {

        // Tell function width has no longer changed
        widthChanged = 0;

        // Match target heights
        matchHeight($target);
      }
    }, settings.buffer));

    // On resize event
    $(window).on('resize', _.debounce(function(){

       // Tell function width has changed
      widthChanged = 1;

      // If this container is visible
      if ($this.inView()) {

        // Match target heights
        matchHeight($target);
      }

    }, settings.buffer))

  };

}( $ ));