/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
window.jQuery = $;
import _ from 'lodash';
import {TweenMax}  from "gsap";
require('gsap/src/minified/plugins/ScrollToPlugin.min.js');
const matchHeight = require('jquery-match-height');
import scrollDir from 'scrolldir';
const select2 = require('select2'); 
// import flatpickr from "flatpickr";
const flatpickr = require("flatpickr");
// import 'datepicker-bootstrap';
// import 'datepicker-bootstrap/js/datepicker.js';
// import {select2} from 'select2';
// const scrollDirection = require('scroll-direction');

/* Imports ---------------------------------------------------------*/

import './parallax.js';
import './templates';
// import './datePicker.js'

// ==========================================//
// Hamburger Open/Close
// ==========================================//

select2($);

$('.js-select2 select').select2({  width:'100%'  ,minimumResultsForSearch: -1});

// flatpickr("#ContactForm_ContactForm_DateAndTime", {});
$("#ContactForm_ContactForm_DateAndTime").flatpickr({
    enableTime: true,
    dateFormat: "Y-m-d H:i",
});

$('#ContactForm_ContactForm_DateAndTime_Holder label').on('focus', function(){
  $(this).find('.flatpickr-input').focus();
})

// (function($){
//   // $('#ContactForm_ContactForm_DateAndTime').datetimepicker({ weekStart : 0, time: true });
//   $('#date').bootstrapMaterialDatePicker({ weekStart : 0, time: false });
// })(jQuery);

scrollDir();

hamburger('#hamburger', '.js-nav', '.js-header');

function hamburger(hamburger, nav, header) {
  var scrollPos;
  var $nav = $(nav);
  var $header = $(header);
  var $hamburger = $(hamburger);
  var $lineOne = $hamburger.find('span:first-child');
  var $lineTwo = $hamburger.find('span:nth-child(2)');
  var $lineThree = $hamburger.find('span:last-child');
  var hamburgerAnimation = new TimelineMax({paused: true});
  var navAnimation = new TimelineMax({paused: true});
  var canClick = 1;

  hamburgerAnimation
  .to([$lineOne, $lineThree], 0.3, {top: "50%"}, 'start')
  .set($lineTwo, {autoAlpha: 1}, 'start')
  .set($lineTwo, {autoAlpha: 0}, 'mid')
  .to($lineOne, 0.3, {rotation: 45}, 'end')
  .to($lineThree, 0.3, {rotation: -45}, 'end');

  navAnimation
    // .to($nav, 1, {display: "none"}, 'init')
    .fromTo($nav, 0.1, {display: "none"}, {display: "flex"}, 'start')
    .from($nav, 0.3, {autoAlpha: 0}, 'start')
    

    $hamburger.on('click', function(){
      if (canClick) {
        canClick = 0;
        if (!$hamburger.hasClass('open')) {
          scrollPos = $(window).scrollTop();
          hamburgerAnimation.restart();
          navAnimation.restart();
          $header.addClass('open');
          $hamburger.addClass('open');
          $nav.addClass('open');
          $('body').addClass('noScrolling');
        }else {
          hamburgerAnimation.reverse();
          navAnimation.reverse();
          $header.removeClass('open');
          $hamburger.removeClass('open');
          $nav.removeClass('open');
          $('body').removeClass('noScrolling');
          $(window).scrollTop(scrollPos);
        }
        setTimeout(function(){
          canClick = 1;
        }, 500);
      }
    })
  } 

// function scrollDirection(e, config) {
//   var $window = $(window);

//   switch (e.type) { 
//     case 'touchstart': 
//       config.tStart = $window.scrollTop();
//       return config;
//       break;

//     case 'touchmove': 
//       config.tEnd = $window.scrollTop();
//       return config;
//       break;   

//     case 'touchend': 
//       config.tEnd = $window.scrollTop();      
//       if (config.tEnd < config.tStart) {
//         console.log('moving')
//         config.direction = 'up';
//       } else {
//         config.direction = 'down';
//       }
//       return config;
//       break;    

//     default:
//       if (e.originalEvent.deltaY < 0) {
//         config.direction = 'up';
//       }else {
//         config.direction = 'down';
//       }
//       return config;
//   }

// }

// function stickElementOnScrollUp($element, posFromTop) {

//   var $window = $(window);
//   var config = {};
//   var direction;

//   $window.on('wheel touchstart touchmove touchend', _.throttle(function(e){
//     direction = scrollDirection(e, config);

//     if (config.direction == 'up' && $window.scrollTop() >= posFromTop) {
//       $element.addClass('stuck');
//     } else {
//       $element.removeClass('stuck');
//     }

//     return;
//   }, 50));
// }


// stickElementOnScrollUp($('.js-header'), $('.js-banner').height());

// $(window).on('scroll')

$('.js-parallax').each(function(){
  $(this).parallax();
});

$('[data-equalize]').each(function(){
  $(this).equalize();
});


// Show header on scroll up
var $jsBanner = $('.js-banner');
var $jsHeader = $('.js-header');
$(window).on('scroll', _.throttle(function(){
  if ($jsBanner.inView()) {
    if (!$jsHeader.hasClass('reset')) {
      $jsHeader.addClass('reset');
    }
  }else {
    if ($jsHeader.hasClass('reset')) {
      $jsHeader.removeClass('reset');
    }

    // if ($('body').data('scrolldir') == 'up') {
    //   console.log('up');
    //   TweenLite.to($jsHeader, 0.3, {yPercent: 0})
    // }else {
    //   TweenLite.to($jsHeader, 0.3, {yPercent: -100})
    // }
  }


  
}, 200));

function accordionAction($trigger, $target) {
  $('.js-accordion-content').slideUp();
  if ($trigger.hasClass('js-open')) {
    $trigger.removeClass('js-open');
    $target.slideUp();
  } else {
        // $('.js-open').removeClass('js-open');
        $trigger.addClass('js-open');
        $target.slideDown();
      }
    }

    $('.js-accordion').each(function () {
      var $parent  = $(this);
      var $trigger = $parent.find('.js-accordion-trigger');

      if ($parent.hasClass('js-open')) {
        $target.css({'display': 'block'});
      }

      $trigger.each(function(){
        var $this = $(this);
        var $target  = $this.siblings('.js-accordion-target');
        var canClick = 1;
        
        $this.on('click', function (e) {
          e.preventDefault();
          if (canClick) {
            canClick = 0;
            accordionAction($this, $target);
            setTimeout(function () {
              canClick = 1;
            }, 400);
          }
        });
      })

    });


// ========================
// Filters
// ========================

function closeSelect($select, $list) {
  $select.removeClass('js-open');
  $list.slideUp(200);
}

$('.js-select').each(function(){
  let $select = $(this);
  let $trigger = $select.find('.js-select-trigger');
  let $list = $select.find('.js-select-list');
  let $item = $select.find('.js-select-item');
  let $option = $select.find('option');
  let $default = $select.find('.js-select-default');
  let $target = $(`[data-filter-target="${$select.attr('for')}"]`);
  let $allTargetItems = $target.find('[data-tag]');
  let $targetItems;

  $trigger.on('click', function(){
    if (!$select.hasClass('js-open')) {
      closeSelect($('.js-select'), $('.js-select-list'));
      $select.addClass('js-open');
      $list.slideDown(200);
    }else {
      closeSelect($select, $list)
    }
  });

  $item.on('click', function(){
    let $this = $(this);

    if (!$this.hasClass('selected')) {
      let tag = $this.data('value');

      $trigger.text($this.text());
      
      closeSelect($select, $list);

      $item.removeClass('selected');
      $this.addClass('selected');
      $option.removeAttr('selected');
      $select.find(`option[data-value=${tag}]`).attr('selected','selected');

      $targetItems = $target.find(`[data-tag="${tag}"]`);

      if (tag == 'All') {
        $allTargetItems.show();
      }else {
        $allTargetItems.hide();
        $targetItems.show();
        
      }


    }
  });

  $default.on('change', function(){
    let $this = $(this);
    let tag = $this.find(':selected').data('value');

    closeSelect($select, $list);

    $item.removeClass('selected');
    $select.find(`.js-select-item[data-value=${tag}]`).addClass('selected');
    $trigger.text($this.find(':selected').text());

    $targetItems = $target.find(`[data-tag="${tag}"]`);

    if (tag == 'All') {
      $allTargetItems.show();
    }else {
      $allTargetItems.hide();
      $targetItems.show();
      
    }
  });


});


$('.js-scroll').each(function(){
  var $this = $(this);
  var $parent;
  var scrollTo;

  if ($this.parents('section').length) {
    $parent = $this.parents('section');
    if ($parent.next().length) {
      scrollTo = $parent.next().offset().top;
    }
  }

  if (scrollTo) {
    $this.on('click', function(){
      TweenLite.to(window, 1.5, {scrollTo: {y:scrollTo, autoKill:false}, ease:Power1.easeInOut});
    })
  } else {
    console.warn('next section did not exist');
    $this.remove();
  }

})



// ========================
// Map
// ========================

var $googleMap = $('.js-map');

if ($googleMap.length) {
  $googleMap.each(function(){
    var $this = $(this);
    var api = $this.data('api-key');
    if (api != undefined) {
      $.ajax({
        url     : `https://maps.googleapis.com/maps/api/js?key=${api}`,
        dataType: "script",
        success : googleMap
      });
    }else {
      console.warn(`Invalid Google Maps API Key: ${api}`);
    }
  })
}

function googleMap() {
  $googleMap.each(function () {
    let lngLat  = { lng: $(this).data('lng'), lat: $(this).data('lat') };
    let zoom = $(this).data('zoom'),
    thisMap = new google.maps.Map(this, {
      center          : lngLat,
      zoom            : zoom,
      disableDefaultUI: true
    });

    let marker = new google.maps.Marker({
      position: lngLat,
      map     : thisMap
            // title: 'Hello World!'
          });
  })
}


// Validation

function isValidEmailAddress(emailAddress) {
  var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
}

$('form').each(function(){
  let $this = $(this);
  let $email = $this.find('input[type="email"]');
  let $submit = $this.find('[type="submit"]');
  let isValid;
  let $input = $this.find('input[required]:not([type="email"]), select, textarea');

  // needsValidation = 1;
  $submit.on('click', function(e){
    $input.each(function(){
      let $thisInput = $(this);
      let name = $thisInput.attr('name');
      if ($(this).val() == '') {
        // console.log('no value');
        e.preventDefault();
        if ($thisInput.prev().hasClass('input-err')) {
          $thisInput.prev().remove();
        }
        $thisInput.before(`<p class="input-err">${name} is not valid</p>`);
      }else {
        if ($thisInput.prev().hasClass('input-err')) {
          $thisInput.prev().remove();
        }
      }
    })
    if ($email.length >= 1) {
      $email.each(function(){
        let $thisInput = $(this);
        let emailAddress = $thisInput.val();
        if (!isValidEmailAddress(emailAddress)) {
          e.preventDefault();
          $this.find('.email-err').remove();
          $thisInput.before('<p class="email-err">Email is not valid</p>')
          // console.log('not valid');
        }
      })
    }
  })
})


function staggerIn($parent, $target) {
  let staggerIn = new TimelineMax();

  staggerIn.staggerFromTo($target, 1.4, { autoAlpha: 0, y: 20, ease: Power2.easeOut }, {autoAlpha: 1, y: 0}, 0.2, 0.2);

  let staggerInScene = new ScrollMagic.Scene({ triggerElement: $parent[0], triggerHook: 1, reverse: false })
  .setTween(staggerIn)
        // .addIndicators('tween', 'element')
        .addTo(controller);

      }

      $('.js-stagger-in').each(function () {
        let $this = $(this);
        staggerIn($this, $this.find('.js-stagger-in-watch:not(.slick-cloned)'));
      })

      $('.js-stagger-in-all').each(function () {
        let $this = $(this);
        staggerIn($this, $this.find('>*:not(.slick-cloned)'));
      })